h1 {
    font-size: 2.25rem;
    @media (min-width: 576px) {
        font-size: 3.3125rem;
        line-height: 1.15em; 
    }
}

h2 {
    font-size: 1.75rem;
    @media (min-width: 576px) {
        font-size: 2.25rem;
        line-height: 1.5em;
    }
}

.mt-1 {
    margin-top: 1em;
}

.text-center {
    text-align: center;
}

.p10 {
    padding: 10px !important;
}

.m8 {
    margin: 8px !important; 
}