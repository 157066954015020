.brand {
  h1 {
    @media (max-width: 576px) {
      font-size: 2.5rem !important;
    }
  }
}

.banner {
  color: #444 !important;
  background: #fff;
  font-size: 1.5625rem;
  line-height: 1.4em;
  padding: 10px 18px;
  border-radius: 3px;
  box-shadow: 0 5px 5px -2px rgba(31, 31, 31, 0.4);
}

.video-container {
  // width: 100vw;
  // height: 100vh;
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 95vw;
    height: 101vh;
    transform: translate(-50%, -50%);
  }
}

h4.video-title,
h1.video-title {
  color: #000 !important;
}

.full-iframe {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 6px;
  }
  .ytp-popup {
    background-color: transparent;
  }
}
