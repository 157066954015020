.team-card {
    .card-body {
        min-height: 175px;
        padding: 0.9375rem 1.5rem;
    }

    .card-avatar {
        width: 130px;
        height: 130px;
        display: flex;
        justify-content: center;
        background: #fff;
        align-items: center;
    }
}
